import * as React from "react";
import {
  useTheme,
  Grid,
  Box,
  Typography,
  Theme,
  Button,
  Link,
} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import { Markdown } from "../../data/types/Markdown";
import { MarkdownBlock } from "../common/MarkdownBlock";
import { usingMobile } from "../../data/usingMobile";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useSocialData } from "../../data/hooks/useSocialData";

export interface IHeroSplashProps {
  introLine: string;
  name: string;
  subtitle: Markdown;
}

export function HeroSplash(props: IHeroSplashProps) {
  const theme = useTheme();
  const isMobile = usingMobile();
  const socialData = useSocialData();
  return (
    <Grid
      container
      spacing={0}
      alignItems="center"
      justifyContent="center"
      sx={{ marginTop: isMobile ? "-40px" : "0px" }}
    >
      <Grid item xs={11} sm={9} component={Box}>
        <Typography
          variant="h5"
          sx={{
            color: (theme: Theme) => theme.palette.primary.main,
            marginBottom: (theme: Theme) => theme.spacing(2),
          }}
        >
          {props.introLine}
        </Typography>
        <Typography
          variant={isMobile ? "h3" : "h1"}
          sx={{
            marginBottom: (theme: Theme) => theme.spacing(2),
            fontWeight: "700",
          }}
        >
          <Box
            component="span"
            sx={{
              color: (theme: Theme) => theme.palette.text.secondary,
            }}
          >
            I'm
          </Box>{" "}
          <Box component="span">{props.name}</Box>
        </Typography>
        <Typography
          variant={isMobile ? "subtitle1" : "h6"}
          sx={{
            color: (theme: Theme) => theme.palette.text.secondary,
            width: "90%",
            marginBottom: (theme: Theme) => theme.spacing(2),
          }}
        >
          <MarkdownBlock data={props.subtitle} />
        </Typography>
        <Button
          variant="contained"
          sx={{
            marginRight: (theme: Theme) => theme.spacing(2),
            fontWeight: "bold",
          }}
          href="#contact"
        >
          Get in touch!
        </Button>
        {!isMobile && (
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<GitHubIcon />}
            component={Link}
            href={socialData.github}
          >
            View my code
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

import * as React from "react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../../client-config";
import { GatsbyImage } from "gatsby-plugin-image";
import { Image } from "../../data/types/Image";
import { useTheme } from "@mui/material";

export interface IAvatarProps {
  image: Image;
  border?: boolean;
  small?: boolean;
}

export function Avatar(props: IAvatarProps) {
  const theme = useTheme();
  const imageData = getGatsbyImageData(
    props.image._rawAsset._ref,
    { width: props.small ? 200 : 380 },
    clientConfig.sanity
  );
  return (
    <GatsbyImage
      image={imageData}
      alt={props.image.alt}
      style={{
        borderRadius: "50%",
        boxShadow: props.border ? `0 0 25px 0 rgb(36 37 52 / 100%)` : "",
        border: props.border ? "6px solid" : "",
        borderColor: theme.palette.background.paper,
      }}
    />
  );
}

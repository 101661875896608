import * as React from "react";
import { SectionContainer } from "../common/SectionContainer";
import { SectionTitle } from "../common/SectionTitle";
import {
  Typography,
  Grid,
  Theme,
  IconButton,
  Box,
  Collapse,
} from "@mui/material";
import { useProjectsData } from "../../data/hooks/useProjectsData";
import { PortfolioItem } from "./PortfolioItem";
import { Link } from "gatsby";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useState } from "react";
import { usingMobile } from "../../data/usingMobile";

function GetProjects() {
  const projects = useProjectsData();
  const featuredProjects = projects.filter((project) => project.featured);
  const otherProjects = projects.filter((project) => !project.featured);

  const allProjects = [...featuredProjects, ...otherProjects];

  var firstProjectsToShow = allProjects.slice(0, 4);
  var additionalProjects = allProjects.slice(4, 8);

  return [firstProjectsToShow, additionalProjects];
}

export function Portfolio() {
  const [featuredProjects, additionalProjects] = GetProjects();
  const [showMore, setShowMore] = useState<boolean>(false);
  const isMobile = usingMobile();
  return (
    <SectionContainer vPadding={isMobile ? "110px" : "200px"} id="portfolio">
      <SectionTitle title="My Portfolio" gutterBottom />
      <Typography
        variant="h6"
        sx={{ marginBottom: (theme: Theme) => theme.spacing(2) }}
      >
        This is a selection of some of my favourite projects. You can view them
        all <Link to="/projects">here</Link>.
      </Typography>
      <Grid container spacing={2}>
        {featuredProjects.map((x) => {
          return <PortfolioItem item={x} key={x.id + "_item"} />;
        })}
      </Grid>
      <Collapse in={showMore} sx={{ marginTop: "16px" }}>
        <Grid container spacing={2}>
          {additionalProjects.map((x) => {
            return <PortfolioItem item={x} key={x.id + "_item"} />;
          })}
        </Grid>
      </Collapse>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "24px",
          marginBottom: "64px",
        }}
      >
        {additionalProjects.length > 0 && (
          <IconButton
            aria-label="view more"
            size="large"
            sx={{
              backgroundColor: (theme: Theme) => theme.palette.background.paper,
              transition: "0.1s",
              ":hover": {
                background: (theme: Theme) => theme.palette.background.paper,
                transform: "translate(0, 4px)",
                color: (theme: Theme) => theme.palette.primary.main,
              },
            }}
            onClick={() => setShowMore(!showMore)}
          >
            <ArrowDownwardIcon
              fontSize="large"
              sx={{
                transform: `rotate(${showMore ? 180 : 0}deg)`,
                transition: "0.5s",
              }}
            />
          </IconButton>
        )}
      </Box>
    </SectionContainer>
  );
}

import { useStaticQuery, graphql } from "gatsby";
import { ExperienceData } from "../types/ExperienceData";

export function useExperienceData(): ExperienceData[] {
  const data = useStaticQuery(graphql`
    query {
      allSanityExperience(sort: { fields: startdate, order: DESC }) {
        edges {
          node {
            id
            duties
            title
            organisation
            technologies
            location
            work
            startdate(formatString: "MMM YYYY")
            enddate(formatString: "MMM YYYY")
          }
        }
      }
    }
  `);

  return data.allSanityExperience.edges.map((x) => x.node as ExperienceData);
}

import { useStaticQuery, graphql } from "gatsby";
import { ContactData } from "../types/ContactData";

export function useContactData() {
  const data = useStaticQuery(graphql`
    query {
      sanityContact {
        _rawContactMe
      }
    }
  `);

  return data.sanityContact as ContactData;
}

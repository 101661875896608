import * as React from "react";
import { TimelineDesktop } from "./timeline/desktop/TimelineDesktop";
import { SectionTitle } from "../common/SectionTitle";
import { SectionContainer } from "../common/SectionContainer";

import { useExperienceData } from "../../data/hooks/useExperienceData";
import { TimelineEntry } from "../../types/TimelineEntry";
import { usingMobile } from "../../data/usingMobile";
import { TimelineMobile } from "./timeline/mobile/TimelineMobile";

export function Experience() {
  const experiences = useExperienceData() as TimelineEntry[];
  experiences[experiences.length - 1].last = true;

  const isMobile = usingMobile();

  return (
    <SectionContainer vPadding={isMobile ? "110px" : "200px"} id="experience">
      <SectionTitle title="Experience" centre gutterBottom />
      {isMobile ? (
        <TimelineMobile entries={experiences} />
      ) : (
        <TimelineDesktop entries={experiences} />
      )}
    </SectionContainer>
  );
}

import * as React from "react";
import { TimelineCard } from "../common/TimelineCard";
import { TimelineEntry } from "../../../../types/TimelineEntry";
import {
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import { Typography, Paper, Theme } from "@mui/material";
import { useState } from "react";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";

export interface ITimelineEntryCardDesktopProps {
  entry: TimelineEntry;
  leftSide: boolean;
}

export function TimelineEntryCardDesktop(
  props: ITimelineEntryCardDesktopProps
) {
  const [hover, setHover] = useState(false);
  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            marginTop: (theme: Theme) => theme.spacing(2.6),
            color: (theme: Theme) =>
              hover ? theme.palette.text.primary : theme.palette.text.secondary,
            transition: "0.3s",
          }}
        >
          {props.entry.startdate} &mdash; {props.entry.enddate ?? "present"}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            background: (theme: Theme) => theme.palette.background.paper,
            padding: (theme: Theme) => theme.spacing(1.6),
            transition: "0.3s",
          }}
          variant="filled"
        >
          {props.entry.work ? (
            <WorkOutlineOutlinedIcon
              sx={{
                color: (theme: Theme) =>
                  `${
                    hover
                      ? theme.palette.primary.main
                      : theme.palette.text.secondary
                  }`,
                transition: "0.3s",
              }}
            />
          ) : (
            <SchoolOutlinedIcon
              sx={{
                color: (theme: Theme) =>
                  `${
                    hover
                      ? theme.palette.primary.main
                      : theme.palette.text.secondary
                  }`,
                transition: "0.3s",
              }}
            />
          )}
        </TimelineDot>
        {props.entry.last ? "" : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Paper
          sx={{
            margin: (theme: Theme) => theme.spacing(2),
            marginBottom: (theme: Theme) => theme.spacing(8),
            marginLeft: props.leftSide ? 0 : (theme: Theme) => theme.spacing(2),
            marginRight: !props.leftSide
              ? 0
              : (theme: Theme) => theme.spacing(2),
            marginTop: 0,
            cursor: "pointer",
            "&:hover": {
              boxShadow: (theme: Theme) => theme.shadows[3],
            },
            transition: "0.3s",
            borderTop: (theme: Theme) =>
              `2px solid ${theme.palette.primary.main}`,
          }}
        >
          <TimelineCard
            jobTitle={props.entry.title}
            company={props.entry.organisation}
            location={props.entry.location}
            duties={props.entry.duties}
            technologies={props.entry.technologies}
            onMouseEnter={() => setHover(true)}
            onMouseExit={() => setHover(false)}
            key={props.entry.organisation}
          />
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
}

import * as React from "react";
import { Layout } from "../components/common/Layout";
import { Hero } from "../components/hero/Hero";
import { About } from "../components/About";
import { Experience } from "../components/experience/Experience";
import { Portfolio } from "../components/portfolio/Portfolio";
import { Contact } from "../components/Contact";
import { Blog } from "../components/blog/Blog";

const IndexPage = () => {
  return (
    <Layout includeFooter includeHeader includeSocials>
      <Hero />
      <About />
      <Experience />
      <Portfolio />
      {/*<Blog />*/}
      <Contact />
    </Layout>
  );
};

export default IndexPage;

import * as React from "react";
import { SectionContainer } from "./common/SectionContainer";
import { SectionTitle } from "./common/SectionTitle";
import { Button, Link, Box } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { usingMobile } from "../data/usingMobile";
import { useSocialData } from "../data/hooks/useSocialData";
import { useContactData } from "../data/hooks/useContactData";
import { MarkdownBlock } from "./common/MarkdownBlock";

const desktopStyle = {
  marginLeft: "25%",
  maxWidth: "50%",
  textAlign: "center",
} as const;

const mobileStyle = {
  padding: "0 10px",
  textAlign: "center",
} as const;

export function Contact() {
  const isMobile = usingMobile();
  const socialData = useSocialData();
  const contactData = useContactData();

  return (
    <div>
      <SectionContainer vPadding="200px" id="contact">
        <SectionTitle title="Get In Touch!" centre />
        <Box sx={isMobile ? mobileStyle : desktopStyle}>
          <MarkdownBlock data={contactData._rawContactMe} />
          <br />
          <Button
            variant="contained"
            sx={{ fontWeight: "bold" }}
            startIcon={<EmailIcon />}
            size="large"
            component={Link}
            href={`mailto:${socialData.emailAddress}`}
          >
            Email me
          </Button>
        </Box>
      </SectionContainer>
    </div>
  );
}

import * as React from "react";
import { TimelineEntry } from "../../../../types/TimelineEntry";
import { TimelineEntryCardMobile } from "./TimelineEntryCardMobile";

export interface ITimelineMobileProps {
  entries: TimelineEntry[];
}

export function TimelineMobile(props: ITimelineMobileProps) {
  return (
    <>
      {props.entries.map((x) => {
        return <TimelineEntryCardMobile entry={x} />;
      })}
    </>
  );
}

import { useStaticQuery, graphql } from "gatsby";
import { HeroSplashData } from "../types/HeroSplashData";

export function useHeroSplashData() {
  const data = useStaticQuery(graphql`
    query {
      sanityHeroSplashData {
        introLine
        _rawSubtitle
      }
    }
  `);

  return data.sanityHeroSplashData as HeroSplashData;
}

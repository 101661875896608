import * as React from "react";
import { Paper, Typography, Chip, Theme, Box } from "@mui/material";

export interface ITimelineCardProps {
  jobTitle: string;
  company: string;
  location: string;
  duties: string[];
  technologies?: string[];
  onMouseEnter?: () => void;
  onMouseExit?: () => void;
}

export function TimelineCard(props: ITimelineCardProps) {
  return (
    <Paper
      elevation={0}
      sx={{
        padding: (theme: Theme) => theme.spacing(3),
        paddingTop: (theme: Theme) => theme.spacing(2),
        marginLeft: 0,
        textAlign: "left",
        borderTop: `2px solid transparent`,
      }}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseExit}
      key={props.company}
    >
      <Box
        component="div"
        sx={{
          marginBottom: (theme: Theme) => theme.spacing(2),
        }}
      >
        <Typography variant="h6" component="h1" fontWeight={600}>
          {props.jobTitle}
        </Typography>
        <Typography variant="subtitle2" component="h2">
          {props.company} - {props.location}
        </Typography>
      </Box>
      <Box
        component="ul"
        sx={{
          paddingLeft: (theme: Theme) => theme.spacing(4),
          listStyle: "disc",
        }}
      >
        {props.duties.map((x, i) => {
          return (
            <Box
              component="li"
              sx={{
                fontSize: (theme: Theme) => theme.typography.body1.fontSize,
              }}
              key={props.company + "_" + i}
            >
              {x}
            </Box>
          );
        })}
      </Box>
      {props.technologies ? (
        <Box
          component="div"
          sx={{
            marginTop: (theme: Theme) => theme.spacing(2),
          }}
        >
          {props.technologies.map((experience) => {
            return (
              <Chip
                sx={{
                  marginRight: (theme: Theme) => theme.spacing(1),
                  cursor: "pointer",
                }}
                size="small"
                label={experience}
                key={props.company + experience}
              />
            );
          })}
        </Box>
      ) : null}
    </Paper>
  );
}

import * as React from "react";
import { SectionContainer } from "../common/SectionContainer";
import { useStaticQuery, graphql } from "gatsby";
import { usePersonalInformation } from "../../data/hooks/usePersonalInformation";
import { useHeroSplashData } from "../../data/hooks/useHeroSplashData";
import { HeroSplash } from "./HeroSplash";

export function Hero() {
  const personalInformation = usePersonalInformation();
  const heroSplashData = useHeroSplashData();

  return (
    <SectionContainer vCentre id="home">
      <HeroSplash
        name={
          personalInformation.firstName + " " + personalInformation.lastName
        }
        introLine={heroSplashData.introLine}
        subtitle={heroSplashData._rawSubtitle}
      />
    </SectionContainer>
  );
}

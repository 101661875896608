import * as React from "react";
import { TimelineEntry } from "../../../../types/TimelineEntry";
import { Paper, Theme, Typography, Box } from "@mui/material";
import { TimelineCard } from "../common/TimelineCard";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

export interface ITimelineEntryCardMobileProps {
  entry: TimelineEntry;
}

export function TimelineEntryCardMobile(props: ITimelineEntryCardMobileProps) {
  return (
    <Paper
      sx={{
        marginBottom: (theme: Theme) => theme.spacing(2),
      }}
    >
      <TimelineCard
        jobTitle={props.entry.title}
        company={props.entry.organisation}
        location={props.entry.location}
        duties={props.entry.duties}
        technologies={props.entry.technologies}
        key={props.entry.organisation}
      />
      <Box
        component="div"
        sx={{
          display: "flex",
          padding: (theme: Theme) => theme.spacing(1),
          alignItems: "center",
        }}
      >
        <CalendarTodayIcon
          sx={{ marginRight: (theme: Theme) => theme.spacing(1) }}
          color="primary"
        />
        <Typography variant="subtitle1">
          {props.entry.startdate} &mdash; {props.entry.enddate ?? "present"}
        </Typography>
      </Box>
    </Paper>
  );
}

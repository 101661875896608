import * as React from "react";
import { Typography, Grid, Link, Theme, Box } from "@mui/material";
import { SectionTitle } from "./common/SectionTitle";

import { SectionContainer } from "./common/SectionContainer";
import { usePersonalInformation } from "../data/hooks/usePersonalInformation";
import { MarkdownBlock } from "./common/MarkdownBlock";
import { Avatar } from "./common/Avatar";
import { usingMobile } from "../data/usingMobile";

const descriptionStyle = {
  paddingBottom: (theme: Theme) => theme.spacing(2),
} as const;

const listStyle = {
  textAlign: "left",
  paddingLeft: (theme: Theme) => theme.spacing(2),
  marginBottom: (theme: Theme) => theme.spacing(4),
} as const;

export function About() {
  const personalInformation = usePersonalInformation();
  const isMobile = usingMobile();
  return (
    <SectionContainer vPadding={isMobile ? "110px" : "150px"} id="about">
      <SectionTitle title="About Me" gutterBottom />
      <Grid container>
        <Grid item sm={12} md={6}>
          <MarkdownBlock data={personalInformation._rawAboutMe} />
        </Grid>
        <Grid item sm={12} md={6}>
          <Box display="flex" justifyContent="center">
            <Avatar image={personalInformation.portrait} border />
          </Box>
        </Grid>
      </Grid>
    </SectionContainer>
  );
}
